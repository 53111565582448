import { handleCall } from './handleResponse'

export const batteriesService = {
  getAllBatteries,
  getBatteryById,
  getAllDynamics,
  getDynamics,
  getBatteriesAssignedToZone,
  getBatteriesAssignedToOperator,
  getBatteriesAssignedToCustomer,
  create,
  assignToOperator,
  assignToZone,
  unassignFromOperator,
  getAllUnassigned,
  getUnassignedByZone,
  unassignFromZoneAndOperatorCia,
  getAllUnassignedZonesAndOperatorCia,
  setOutOfService,
  assignToZoneAndOperator,
  getUnassignedsToOperatorCiaByZones,
  deletebat,
  getBatteryRequests,
  getDeliverableForLogged,
  deliverToCustomer,
  getForLogged,
  setChecked,
  deliveryUpdateStation,
  getBattery1ToManualSwapSelect,
  getBattery2ToManualSwapSelect,
  getDeliverableInStation,
  createBatteryInsertion,
  assignToStation,
}
function deletebat(id) {
  return handleCall(`/batteries/v1/Batteries/${id}`, {
    method: 'DELETE',
    body: null,
  })
}

function getUnassignedsToOperatorCiaByZones(filter = []) {
  // '?zones[]=sdsds&zones[]=sdsdsdsdsdsds'
  let filterUrl = ''
  filter.map((i, index) => {
    if (index === 0) {
      filterUrl = `?zones[]=${i}`
    } else {
      filterUrl += `&zones[]=${i}`
    }
  })

  return handleCall(
    `/batteries/v1/Batteries/GetUnassignedsToOperatorCiaByZones${filterUrl}`,
    {
      method: 'GET',
      body: null,
    },
  )
}

function assignToZoneAndOperator(body) {
  return handleCall(`/stations/v1/Batteries/AssignToZoneAndOperatorCia`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}

function deliverToCustomer(body) {
  return handleCall(`/stations/v2/deliveries/${body.id}`, {
    method: 'PATCH',
    body: JSON.stringify(body.batteryPackIds),
  })
}

function setOutOfService(body) {
  const { id } = body
  delete body.id
  return handleCall(`/batteries/v1/Batteries/${id}/OutOfService`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}

function getAllUnassignedZonesAndOperatorCia() {
  return handleCall(
    `/batteries/v1/Batteries/GetUnassignedsToZoneAndOperatorCia`,
    {
      method: 'GET',
      body: null,
    },
  )
}

function getBattery1ToManualSwapSelect(subscription) {
  return handleCall(
    `/stations/v1/Batteries/GetBattery1ToManualSwapSelect?subscriptionId=` +
      subscription,
    {
      method: 'GET',
      body: null,
    },
  )
}

function getBattery2ToManualSwapSelect(stationId) {
  return handleCall(
    `/stations/v1/Batteries/GetBattery2ToManualSwapSelect?stationId=` +
      stationId,
    {
      method: 'GET',
      body: null,
    },
  )
}

function getBatteryRequests() {
  return handleCall(`/stations/v1/deliveries/GetForLogged`, {
    method: 'GET',
    body: null,
  })
}

function getDeliverableForLogged(id) {
  return handleCall(
    `/stations/v1/batteries/GetDeliverableForLogged?deliveryId=` + id,
    {
      method: 'GET',
      body: null,
    },
  )
}

function unassignFromZoneAndOperatorCia(body) {
  return handleCall(`/stations/v1/Batteries/UnassignFromZoneAndOperatorCia`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}

function getUnassignedByZone(body) {
  return handleCall(`/batteries/v1/Batteries/GetUnassignedByZones`, {
    method: 'GET',
    body: JSON.stringify(body),
  })
}

function getAllUnassigned() {
  return handleCall(`/batteries/v1/Batteries/GetUnassignedsToOperatorCia`, {
    method: 'GET',
    body: null,
  })
}

function unassignFromOperator(body) {
  return handleCall(`/stations/v1/Batteries/UnassignFromOperatorCia`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}

function assignToZone(body) {
  return handleCall(`/stations/v1/Batteries/AssignToZone`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}

function assignToOperator(body) {
  return handleCall(`/stations/v1/Batteries/AssignToOperatorCia`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}

function create(body) {
  return handleCall(`/stations/v1/Batteries/Create`, {
    method: 'POST',
    body: JSON.stringify(body),
  })
}

function getBatteriesAssignedToCustomer(customerId) {
  return handleCall(
    `/stations/v1/batteries/GetCurrentByCustomer?customerId=${customerId}`,
    {
      method: 'GET',
      body: null,
    },
  )
}

function getBatteriesAssignedToOperator(operatorId) {
  return handleCall(
    `/batteries/v1/batteries/GetByOperatorCia?operatorCiaId=${operatorId}`,
    {
      method: 'GET',
      body: null,
    },
  )
}

function getBatteriesAssignedToZone(zoneId) {
  return handleCall(`/batteries/v1/batteries/GetByZone?zoneId=${zoneId}`, {
    method: 'GET',
    body: null,
  })
}

function getDynamics(batteryId) {
  return handleCall(`/batteries/v1/batteries/${batteryId}`, {
    method: 'GET',
    body: null,
  })
}

function getAllDynamics() {
  return handleCall(`/batteries/v1/batteries`, { method: 'GET', body: null })
}

function getBatteryById(batteryId) {
  return handleCall(`/batteries/v1/batteries/${batteryId}`, {
    method: 'GET',
    body: null,
  })
}

function getAllBatteries() {
  return handleCall(`/batteries/v1/batteries/GetAll`, {
    method: 'GET',
    body: null,
  })
}

function getForLogged() {
  return handleCall(`/stations/v1/Batteries/GetForLogged`, {
    method: 'GET',
    body: null,
  })
}

function setChecked(id, check) {
  return handleCall(
    `/stations/v1/Batteries/${id}/SetAccountingCheck?check=${check}`,
    {
      method: 'PATCH',
      body: null,
    },
  )
}

function deliveryUpdateStation(id, station) {
  return handleCall(
    `/stations/v1/Deliveries/${id}/UpdateStation?stationId=${station}`,
    {
      method: 'PATCH',
      body: null,
    },
  )
}

function getDeliverableInStation(id) {
  return handleCall(
    `/stations/v1/Batteries/GetDeliverableInStation?stationId=${id}`,
    {
      method: 'GET',
      body: null,
    },
  )
}

function createBatteryInsertion(body) {
  return handleCall('/stations/v1/BatteryInsertions', {
    method: 'POST',
    body,
  })
}

function assignToStation(body) {
  return handleCall(`/stations/v1/Batteries/AssignToStation`, {
    method: 'PUT',
    body,
  })
}
