import { detectMaxRole, rolesConstants } from './roles'

export const roleLinkList = [
  {
    link: '/home',
    role: [
      rolesConstants.BS_ADMIN,
      rolesConstants.BS_ADMINREADONLY,
      rolesConstants.SAC_USER,
    ],
  },
  {
    link: '/login',
    role: [
      rolesConstants.BS_ADMIN,
      rolesConstants.BS_ADMINREADONLY,
      rolesConstants.BS_OPERATOR,
      rolesConstants.BS_OPERATOR_MANAGER,
      rolesConstants.BS_TECHNICIAN,
      rolesConstants.BS_SUPERVISOR,
      rolesConstants.SAC_USER,
      rolesConstants.CAU_USER,
    ],
  },
  {
    link: '/customers',
    role: [
      rolesConstants.BS_ADMIN,
      rolesConstants.BS_ADMINREADONLY,
      rolesConstants.SAC_USER,
      rolesConstants.CAU_USER,
      rolesConstants.BS_FINANCIAL,
    ],
  },
  {
    link: '/zones',
    role: [rolesConstants.BS_ADMIN, rolesConstants.BS_ADMINREADONLY],
  },
  {
    link: '/subscriptions',
    role: [
      rolesConstants.BS_ADMIN,
      rolesConstants.BS_ADMINREADONLY,
      rolesConstants.SAC_USER,
      rolesConstants.CAU_USER,
    ],
  },
  {
    link: '/companies',
    role: [
      rolesConstants.BS_ADMIN,
      rolesConstants.BS_ADMINREADONLY,
      rolesConstants.BS_OPERATOR,
      rolesConstants.BS_FINANCIAL,
    ],
  },
  {
    link: '/operatorUser',
    role: [rolesConstants.BS_OPERATOR],
  },
  {
    link: '/technicians',
    role: [rolesConstants.BS_OPERATOR_MANAGER, rolesConstants.BS_OPERATOR],
  },
  {
    link: '/supervisors',
    role: [rolesConstants.BS_OPERATOR],
  },
  {
    link: '/operators',
    role: [
      rolesConstants.BS_ADMIN,
      rolesConstants.BS_ADMINREADONLY,
      rolesConstants.SAC_USER,
      rolesConstants.CAU_USER,
      rolesConstants.BS_TECHNICIAN,
      rolesConstants.BS_FINANCIAL,
    ],
  },
  {
    link: '/users',
    role: [rolesConstants.BS_ADMIN, rolesConstants.BS_ADMINREADONLY],
  },
  {
    link: '/stations',
    role: [
      rolesConstants.BS_ADMIN,
      rolesConstants.BS_ADMINREADONLY,
      rolesConstants.BS_OPERATOR,
      rolesConstants.BS_OPERATOR_MANAGER,
      rolesConstants.BS_SUPERVISOR,
      rolesConstants.ALTECH_OPERATIONS,
      rolesConstants.SAC_USER,
      rolesConstants.CAU_USER,
      rolesConstants.BS_TECHNICIAN,
      rolesConstants.BS_FINANCIAL,
    ],
  },
  {
    link: '/batteries',
    role: [
      rolesConstants.BS_OPERATOR_MANAGER,
      rolesConstants.BS_ADMIN,
      rolesConstants.BS_ADMINREADONLY,
      rolesConstants.BS_OPERATOR,
      rolesConstants.BS_SUPERVISOR,
      rolesConstants.BS_FINANCIAL,
      rolesConstants.SAC_USER,
      rolesConstants.CAU_USER,
      rolesConstants.BS_TECHNICIAN,
    ],
  },
  {
    link: '/requestBattery',
    role: [
      rolesConstants.BS_OPERATOR_MANAGER,
      rolesConstants.BS_ADMIN,
      rolesConstants.BS_ADMINREADONLY,
      rolesConstants.BS_OPERATOR,
      rolesConstants.BS_SUPERVISOR,
      rolesConstants.SAC_USER,
      rolesConstants.CAU_USER,
      rolesConstants.BS_TECHNICIAN,
    ],
  },
  {
    link: '/returnBatteries',
    role: [
      rolesConstants.BS_ADMIN,
      rolesConstants.BS_ADMINREADONLY,
      rolesConstants.BS_OPERATOR_MANAGER,
      rolesConstants.BS_OPERATOR,
      rolesConstants.SAC_USER,
      rolesConstants.CAU_USER,
      rolesConstants.BS_TECHNICIAN,
    ],
  },
  {
    link: '/map',
    role: [
      rolesConstants.BS_ADMIN,
      rolesConstants.BS_ADMINREADONLY,
      rolesConstants.BS_OPERATOR,
      rolesConstants.BS_OPERATOR_MANAGER,
      rolesConstants.BS_SUPERVISOR,
      rolesConstants.SAC_USER,
      rolesConstants.CAU_USER,
      rolesConstants.BS_TECHNICIAN,
    ],
  },
  {
    link: '/swaps',
    role: [
      rolesConstants.BS_OPERATOR_MANAGER,
      rolesConstants.BS_ADMIN,
      rolesConstants.BS_ADMINREADONLY,
      rolesConstants.BS_OPERATOR,
      rolesConstants.SAC_USER,
      rolesConstants.CAU_USER,
      rolesConstants.BS_TECHNICIAN,
      rolesConstants.BS_FINANCIAL,
      rolesConstants.BS_SUPERVISOR,
    ],
  },
  {
    link: '/insurances',
    role: [
      rolesConstants.BS_ADMIN,
      rolesConstants.SAC_USER,
      rolesConstants.CAU_USER,
      rolesConstants.BS_ADMINREADONLY,
    ],
  },
  {
    link: '/incidents',
    role: [
      rolesConstants.BS_ADMIN,
      rolesConstants.BS_ADMINREADONLY,
      rolesConstants.BS_OPERATOR,
      rolesConstants.BS_OPERATOR_MANAGER,
      rolesConstants.BS_TECHNICIAN,
      rolesConstants.BS_SUPERVISOR,
      rolesConstants.SAC_USER,
      rolesConstants.CAU_USER,
      rolesConstants.BS_TECHNICIAN,
      rolesConstants.BS_FINANCIAL,
    ],
  },
  {
    link: '/myData',
    role: [
      rolesConstants.BS_ADMIN,
      rolesConstants.BS_ADMINREADONLY,
      rolesConstants.BS_OPERATOR,
      rolesConstants.BS_OPERATOR_MANAGER,
      rolesConstants.BS_TECHNICIAN,
      rolesConstants.BS_SUPERVISOR,
      rolesConstants.BS_FINANCIAL,
      rolesConstants.BS_TECHNICIAN,
    ],
  },
  {
    link: '/products',
    role: [
      rolesConstants.BS_ADMIN,
      rolesConstants.BS_ADMINREADONLY,
      rolesConstants.BS_FINANCIAL,
    ],
  },
  {
    link: '/payments',
    role: [
      rolesConstants.BS_OPERATOR_MANAGER,
      rolesConstants.BS_ADMIN,
      rolesConstants.BS_ADMINREADONLY,
      rolesConstants.BS_FINANCIAL,
      rolesConstants.SAC_USER,
      rolesConstants.CAU_USER,
    ],
  },
  {
    link: '/help',
    role: [
      rolesConstants.BS_ADMIN,
      rolesConstants.BS_ADMINREADONLY,
      rolesConstants.BS_OPERATOR,
      rolesConstants.BS_OPERATOR_MANAGER,
      rolesConstants.BS_SUPERVISOR,
      rolesConstants.BS_FINANCIAL,
      rolesConstants.BS_TECHNICIAN,
    ],
  },
  {
    link: '/rates',
    role: [
      rolesConstants.BS_ADMIN,
      rolesConstants.BS_ADMINREADONLY,
      rolesConstants.SAC_USER,
      rolesConstants.CAU_USER,
    ],
  },
  {
    link: '/swapsManual',
    role: [
      rolesConstants.BS_ADMIN,
      rolesConstants.BS_ADMINREADONLY,
      rolesConstants.BS_OPERATOR_MANAGER,
      rolesConstants.BS_OPERATOR,
      rolesConstants.SAC_USER,
      rolesConstants.CAU_USER,
      rolesConstants.BS_TECHNICIAN,
      rolesConstants.BS_SUPERVISOR,
    ],
  },
  {
    link: '/automatic-reservation',
    role: [
      rolesConstants.BS_ADMIN,
      rolesConstants.BS_ADMINREADONLY,
      rolesConstants.SAC_USER,
    ],
  },
  {
    link: '/reservation-attempts',
    role: [
      rolesConstants.BS_ADMIN,
      rolesConstants.BS_ADMINREADONLY,
      rolesConstants.SAC_USER,
    ],
  },
]

export const checkRelationRoleAndLinks = (authenticationReducer, link) => {
  const role = detectMaxRole(
    authenticationReducer.hasOwnProperty('user')
      ? authenticationReducer.user.roles
      : [],
  )

  let result = []
  roleLinkList.map((rl) => {
    if (rl.link === link) {
      rl.role.filter((r) => r === role && result.push(r))
    }
  })

  return result.length > 0
}
