import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import React, { useEffect, useState } from 'react'

import Box from '@material-ui/core/Box'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'

import { connect } from 'react-redux'
import { batteriesActions } from '../../../redux/actions/batteries_actions'
import { discountsActions } from '../../../redux/actions/discounts_actions'

import { isEqual } from '../../../helpers/compareArrays'

import DiscountsByCustomers from '../../discounts/byCustomers'

import moment from 'moment'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Discounts = ({
  isOpen,
  onClose,
  selectedRow,

  batteriesassignToOperator,
  batteriesunassignFromOperator,

  discountsReducer,
  getByCustomer,
  getAll,

  assignDiscount,
  unassignDiscount,

  assignManager,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()

  const { byCustomer, results } = discountsReducer

  const [selectedDiscount, setselectedDiscount] = useState([])
  const [tableDataDiscounts, settableDataDiscounts] = useState([])
  const [readOnlyConfiguration, setreadOnlyConfiguration] = useState(true)

  useEffect(() => {
    settableDataDiscounts([...results, ...byCustomer])
  }, [byCustomer, results])

  const handleEnter = () => {
    const { id } = selectedRow
    id && getByCustomer(id)

    const baseDate = new Date()

    const since = moment(baseDate).subtract(10, 'year').format('YYYY-MM-DD')
    const until = moment(baseDate).add(10, 'year').format('YYYY-MM-DD')

    getAll({ since, until })
  }
  const handleClose = () => {
    onClose()
    setreadOnlyConfiguration(true)
    window.location.href = '/customers'
  }

  const handleselectedDiscountUpdate = (rows) => {
    setselectedDiscount(rows)
  }

  const handleSaveAssignment = () => {
    if (!isEqual(byCustomer, selectedDiscount)) {
      let discountsToAssign = []
      let discountsToUnassign = []

      byCustomer.map((i) => {
        !selectedDiscount.find((s) => s.id === i.id) &&
          discountsToUnassign.push(i)
      })
      selectedDiscount.map((i) => {
        !byCustomer.find((s) => s.id === i.id) && discountsToAssign.push(i)
      })

      const bodyUnassignDiscoutns = {
        discounts: discountsToUnassign.map((i) => i.id),
        customerId: selectedRow.id,
      }
      const bodyAssignDiscounts = {
        discounts: discountsToAssign.map((i) => i.id),
        customerId: selectedRow.id,
      }

      assignManager(bodyAssignDiscounts, bodyUnassignDiscoutns)
      // discountsToAssign.length > 0 && assignDiscount(bodyAssignDiscounts);
      // discountsToUnassign.length > 0 && unassignDiscount(bodyUnassignDiscoutns);

      handleClose()
    }
  }

  const handleConfirmBtn = () => {
    setreadOnlyConfiguration((prev) => !prev)
    !readOnlyConfiguration && handleSaveAssignment()
  }

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onEnter={handleEnter}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {t('customers.dialogs.discounts.dialogTitle')} (
            {selectedRow.fullName})
          </Typography>
          <Button
            autoFocus
            color="inherit"
            onClick={() => setreadOnlyConfiguration(true)}
          >
            {!readOnlyConfiguration && t('operator.assignment.cancelBtn')}
          </Button>
          <Button autoFocus color="inherit" onClick={handleConfirmBtn}>
            {readOnlyConfiguration
              ? t('operator.assignment.confirmBtnEdit')
              : t('operator.assignment.confirmBtnSave')}
          </Button>
        </Toolbar>
      </AppBar>
      <div className={classes.root}>
        <DiscountsByCustomers
          customer={selectedRow}
          addActionCreateDiscount
          onSelectionUpdate={handleselectedDiscountUpdate}
          data={tableDataDiscounts}
          preSelectedRows={byCustomer}
          readOnlyConfiguration={readOnlyConfiguration}
        />
      </div>
    </Dialog>
  )
}

function mapState(state) {
  const { discountsReducer } = state
  return { discountsReducer }
}
const actionCreators = {
  batteriesassignToOperator: batteriesActions.assignToOperator,
  batteriesunassignFromOperator: batteriesActions.unassignFromOperator,

  getByCustomer: discountsActions.getByCustomer,
  getAll: discountsActions.getAll,

  assignDiscount: discountsActions.assignDiscount,
  unassignDiscount: discountsActions.unassignDiscount,

  assignManager: discountsActions.assignManager,
}

export default connect(mapState, actionCreators)(Discounts)
