import { handleCall } from './handleResponse'

export const devolutionService = {
  getAll,
  getById,
  confirm,
}

function getAll() {
  return handleCall(`/stations/v1/Devolution/GetAll`, {
    method: 'GET',
    body: null,
  })
}

function confirm(id, refundDeposit, stolenBatteries) {
  stolenBatteries = stolenBatteries.map((i) => parseInt(i))
  return handleCall(`/stations/v2/devolutions/${id}/confirmation`, {
    method: 'PATCH',
    body: JSON.stringify({ refundDeposit, stolenBatteries }),
  })
}

function getById(id) {
  return handleCall(`/stations/v1/Devolution/${id}`, {
    method: 'GET',
    body: null,
  })
}
