import { batteriesConstants } from '../../constants/batteries_constants'
import { batteriesService } from '../../services/batteriesServices'
import { alertActions } from './alert_actions'

import i18next from 'i18next'

import { getOperatorId } from '../../helpers/operator'
import { isManager } from '../../helpers/roles'

export const batteriesActions = {
  getAllBatteries,
  getBatteryById,
  getAllDynamics,
  getDynamics,
  getBatteriesAssignedToZone,
  getBatteriesAssignedToOperator,
  getBatteriesAssignedToCustomer,
  create,
  assignToOperator,
  assignToZone,
  unassignFromOperator,
  getAllUnassigned,
  getUnassignedByZone,
  unassignFromZoneAndOperatorCia,
  getAllUnassignedZonesAndOperatorCia,
  setOutOfService,
  assignToZoneAndOperator,
  getUnassignedsToOperatorCiaByZones,
  deletebat,
  getBatteryRequests,
  getDeliverableForLogged,
  deliverToCustomer,
  getForLogged,
  setChecked,
  deliveryUpdateStation,
  getDeliverablesInStation,
  createBatteryInsertion,
  assignToStation,
}
function deletebat(id) {
  return (dispatch) => {
    batteriesService.deletebat(id).then(
      (results) => {
        dispatch(getAllBatteries())
        dispatch(
          alertActions.success(i18next.t('services.batteries.deletebat')),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(i18next.t('services.batteries.deletebatError')),
        )
      },
    )
  }
}

function getUnassignedsToOperatorCiaByZones(filter) {
  return (dispatch) => {
    dispatch(request())
    batteriesService.getUnassignedsToOperatorCiaByZones(filter).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return {
      type: batteriesConstants.GET_UNASSIGNED_TO_ZONE_AND_OPERATORCIA_REQUEST,
    }
  }

  function success(results) {
    return {
      type: batteriesConstants.GET_UNASSIGNED_TO_ZONE_AND_OPERATORCIA_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: batteriesConstants.GET_UNASSIGNED_TO_ZONE_AND_OPERATORCIA_FAILURE,
      error,
    }
  }
}

function getBatteryRequests() {
  return (dispatch) => {
    dispatch(request())
    batteriesService.getBatteryRequests().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return {
      type: batteriesConstants.GET_PICKUP_REQUEST_REQUEST,
    }
  }

  function success(results) {
    return {
      type: batteriesConstants.GET_PICKUP_REQUEST_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: batteriesConstants.GET_PICKUP_REQUEST_FAILURE,
      error,
    }
  }
}

function getDeliverableForLogged(id) {
  return (dispatch) => {
    dispatch(request())
    batteriesService.getDeliverableForLogged(id).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return {
      type: batteriesConstants.GET_BATTERIES_AVAILABLE_REQUEST,
    }
  }

  function success(results) {
    return {
      type: batteriesConstants.GET_BATTERIES_AVAILABLE_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: batteriesConstants.GET_BATTERIES_AVAILABLE_FAILURE,
      error,
    }
  }
}

function assignToZoneAndOperator(body) {
  return (dispatch) => {
    batteriesService.assignToZoneAndOperator(body).then(
      (results) => {
        dispatch(getAllBatteries())
        dispatch(
          alertActions.success(
            i18next.t('services.batteries.assignToZoneAndOperatorSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(
            `${i18next.t(
              'services.batteries.assignToZoneAndOperatorError',
            )}: ${error}`,
          ),
        )
      },
    )
  }
}

function setOutOfService(body) {
  const { id } = body
  return (dispatch) => {
    batteriesService.setOutOfService(body).then(
      (results) => {
        dispatch(getBatteryById(id))
        dispatch(
          alertActions.success(
            i18next.t('services.batteries.setOutOfServiceSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(
            i18next.t('services.batteries.setOutOfServiceError'),
          ),
        )
      },
    )
  }
}

function getAllUnassignedZonesAndOperatorCia() {
  return (dispatch) => {
    dispatch(request())
    batteriesService.getAllUnassignedZonesAndOperatorCia().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return {
      type: batteriesConstants.GET_UNASSIGNED_BY_ZONE_AND_OPERATORCIA_REQUEST,
    }
  }

  function success(results) {
    return {
      type: batteriesConstants.GET_UNASSIGNED_BY_ZONE_AND_OPERATORCIA_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: batteriesConstants.GET_UNASSIGNED_BY_ZONE_AND_OPERATORCIA_FAILURE,
      error,
    }
  }
}

function unassignFromZoneAndOperatorCia(body) {
  const { zoneId } = body
  delete body.zoneId
  return (dispatch) => {
    batteriesService.unassignFromZoneAndOperatorCia(body).then(
      (results) => {
        dispatch(getAllBatteries())
        dispatch(getAllUnassignedZonesAndOperatorCia())
        dispatch(getBatteriesAssignedToZone(zoneId))

        dispatch(
          alertActions.success(
            i18next.t(
              'services.batteries.unassignFromZoneAndOperatorCiaSuccess',
            ),
          ),
        )
      },
      (error) => {
        // dispatch(failure(error));
        dispatch(
          alertActions.error(
            i18next.t('services.batteries.unassignFromZoneAndOperatorCiaError'),
          ),
        )
      },
    )
  }
}

function getUnassignedByZone(zoneId) {
  return (dispatch) => {
    dispatch(request())
    batteriesService.getUnassignedByZone(zoneId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return {
      type: batteriesConstants.GET_UNASSIGNED_BY_ZONE_REQUEST,
    }
  }

  function success(results) {
    return {
      type: batteriesConstants.GET_UNASSIGNED_BY_ZONE_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: batteriesConstants.GET_UNASSIGNED_BY_ZONE_FAILURE,
      error,
    }
  }
}

function getAllUnassigned() {
  return (dispatch) => {
    dispatch(request())
    batteriesService.getAllUnassigned().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return {
      type: batteriesConstants.GET_ALL_UNASSIGNED_REQUEST,
    }
  }

  function success(results) {
    return {
      type: batteriesConstants.GET_ALL_UNASSIGNED_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: batteriesConstants.GET_ALL_UNASSIGNED_FAILURE,
      error,
    }
  }
}

function unassignFromOperator(body) {
  const { operatorCiaId } = body
  delete body.operatorCiaId
  return (dispatch) => {
    batteriesService.unassignFromOperator(body).then(
      (results) => {
        dispatch(getAllBatteries())
        dispatch(getAllUnassigned())
        dispatch(getBatteriesAssignedToOperator(operatorCiaId))

        dispatch(
          alertActions.success(
            i18next.t('services.batteries.unassignFromOperatorSuccess'),
          ),
        )
      },
      (error) => {
        // dispatch(failure(error));
        dispatch(
          alertActions.error(
            i18next.t('services.batteries.unassignFromOperatorError'),
          ),
        )
      },
    )
  }
}

function assignToZone(body) {
  const { zoneId } = body
  return (dispatch) => {
    batteriesService.assignToZone(body).then(
      (results) => {
        // dispatch(getAllBatteries());
        dispatch(getAllUnassignedZonesAndOperatorCia())
        dispatch(getBatteriesAssignedToZone(zoneId))
        dispatch(
          alertActions.success(
            i18next.t('services.batteries.assignToZoneSuccess'),
          ),
        )
      },
      (error) => {
        // dispatch(failure(error));
        dispatch(
          alertActions.error(i18next.t('services.batteries.assignToZoneError')),
        )
      },
    )
  }
}

function deliverToCustomer(body) {
  return (dispatch) => {
    batteriesService.deliverToCustomer(body).then(
      (results) => {
        dispatch(getBatteryRequests())
        dispatch(
          alertActions.success(
            i18next.t('services.batteries.deliverToCustomerSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(alertActions.error(
          i18next.t(`services.batteries.errors.${error}`)
        ))
      },
    )
  }
}

function assignToOperator(body) {
  const { operatorCiaId } = body
  return (dispatch) => {
    batteriesService.assignToOperator(body).then(
      (results) => {
        dispatch(getAllBatteries())
        dispatch(getAllUnassigned())
        dispatch(getBatteriesAssignedToOperator(operatorCiaId))
        dispatch(
          alertActions.success(
            i18next.t('services.batteries.assignToOperatorSuccess'),
          ),
        )
      },
      (error) => {
        // dispatch(failure(error));
        dispatch(
          alertActions.error(
            i18next.t('services.batteries.assignToOperatorError'),
          ),
        )
      },
    )
  }
}

function create(body) {
  return (dispatch) => {
    batteriesService.create(body).then(
      (results) => {
        dispatch(getForLogged())
        dispatch(
          alertActions.success(i18next.t('services.batteries.createSuccess')),
        )
      },
      (error) => {
        // dispatch(failure(error));
        dispatch(
          alertActions.error(
            `${i18next.t('services.batteries.createError')}: ${error}`,
          ),
        )
      },
    )
  }
}

function getBatteriesAssignedToCustomer(customerId) {
  return (dispatch) => {
    dispatch(request())
    batteriesService.getBatteriesAssignedToCustomer(customerId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return {
      type: batteriesConstants.GET_BATTERIES_ASSIGNED_TO_CUSTOMER_ID_REQUEST,
    }
  }

  function success(results) {
    return {
      type: batteriesConstants.GET_BATTERIES_ASSIGNED_TO_CUSTOMER_ID_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: batteriesConstants.GET_BATTERIES_ASSIGNED_TO_CUSTOMER_ID_FAILURE,
      error,
    }
  }
}

function getBatteriesAssignedToOperator(operatorId) {
  return (dispatch) => {
    const id = getOperatorId() || operatorId
    if (id) {
      dispatch(request())
      batteriesService.getBatteriesAssignedToOperator(id).then(
        (results) => {
          dispatch(success(results))
        },
        (error) => {
          dispatch(failure(error))
        },
      )
    }
  }

  function request() {
    return {
      type: batteriesConstants.GET_BATTERIES_ASSIGNED_TO_OPERATOR_REQUEST,
    }
  }

  function success(results) {
    return {
      type: batteriesConstants.GET_BATTERIES_ASSIGNED_TO_OPERATOR_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: batteriesConstants.GET_BATTERIES_ASSIGNED_TO_OPERATOR_FAILURE,
      error,
    }
  }
}

function getBatteriesAssignedToZone(zoneId) {
  return (dispatch) => {
    dispatch(request())
    batteriesService.getBatteriesAssignedToZone(zoneId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: batteriesConstants.GET_BATTERIES_ASSIGNED_TO_ZONE_REQUEST }
  }

  function success(results) {
    return {
      type: batteriesConstants.GET_BATTERIES_ASSIGNED_TO_ZONE_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: batteriesConstants.GET_BATTERIES_ASSIGNED_TO_ZONE_FAILURE,
      error,
    }
  }
}

function getDynamics(batteryId) {
  return (dispatch) => {
    dispatch(request())
    batteriesService.getDynamics(batteryId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: batteriesConstants.GET_DYNAMICS_BY_BATTERY_ID_REQUEST }
  }

  function success(results) {
    return {
      type: batteriesConstants.GET_DYNAMICS_BY_BATTERY_ID_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return {
      type: batteriesConstants.GET_DYNAMICS_BY_BATTERY_ID_FAILURE,
      error,
    }
  }
}

function getAllDynamics() {
  return (dispatch) => {
    dispatch(request())
    batteriesService.getAllDynamics().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: batteriesConstants.GET_ALL_DYNAMICS_REQUEST }
  }

  function success(results) {
    return { type: batteriesConstants.GET_ALL_DYNAMICS_SUCCESS, results }
  }

  function failure(error) {
    return { type: batteriesConstants.GET_ALL_DYNAMICS_FAILURE, error }
  }
}

function getBatteryById(batteryId) {
  return (dispatch) => {
    dispatch(request())
    batteriesService.getBatteryById(batteryId).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: batteriesConstants.GET_BY_BATTERY_ID_REQUEST }
  }

  function success(results) {
    return { type: batteriesConstants.GET_BY_BATTERY_ID_SUCCESS, results }
  }

  function failure(error) {
    return { type: batteriesConstants.GET_BY_BATTERY_ID_FAILURE, error }
  }
}

function getAllBatteries() {
  return (dispatch) => {
    if (isManager()) {
      dispatch(request())
      batteriesService.getAllBatteries().then(
        (results) => {
          dispatch(success(results))
        },
        (error) => {
          dispatch(failure(error))
        },
      )
    } else {
      dispatch(getBatteriesAssignedToOperator())
    }
  }

  function request() {
    return { type: batteriesConstants.GET_ALL_REQUEST }
  }

  function success(results) {
    return { type: batteriesConstants.GET_ALL_SUCCESS, results }
  }

  function failure(error) {
    return { type: batteriesConstants.GET_ALL_FAILURE, error }
  }
}

function getForLogged() {
  return (dispatch) => {
    dispatch(request())
    batteriesService.getForLogged().then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: batteriesConstants.GET_BATTERIES_FOR_LOGGED_REQUEST }
  }

  function success(results) {
    return {
      type: batteriesConstants.GET_BATTERIES_FOR_LOGGED_SUCCESS,
      results,
    }
  }

  function failure(error) {
    return { type: batteriesConstants.GET_BATTERIES_FOR_LOGGED_FAILURE, error }
  }
}

function setChecked(id, check) {
  return (dispatch) => {
    dispatch(request())
    batteriesService.setChecked(id, check).then(
      (results) => {
        dispatch(getForLogged())
        dispatch(
          alertActions.success(
            i18next.t(
              `batteries.page.table.actions.${
                check ? 'check' : 'uncheck'
              }Success`,
            ),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(
            i18next.t(
              `batteries.page.table.actions.${
                check ? 'check' : 'uncheck'
              }Error`,
            ),
          ),
        )
      },
    )
  }

  function request() {
    return { type: batteriesConstants.GET_BATTERY_CHECK_REQUEST }
  }
}

function deliveryUpdateStation(id, station) {
  return (dispatch) => {
    dispatch(request())
    batteriesService.deliveryUpdateStation(id, station).then(
      (results) => {
        dispatch(success(results))
        dispatch(getBatteryRequests())
        dispatch(alertActions.success('Entrega modificada correctamente'))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: batteriesConstants.UPDATE_PICKUP_REQUEST }
  }

  function success(results) {
    return { type: batteriesConstants.UPDATE_PICKUP_SUCCESS, results }
  }

  function failure(error) {
    return { type: batteriesConstants.UPDATE_PICKUP_FAILURE, error }
  }
}

function getDeliverablesInStation(id) {
  return (dispatch) => {
    dispatch(request())
    batteriesService.getDeliverableInStation(id).then(
      (results) => {
        dispatch(success(results))
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: batteriesConstants.GET_DELIVERABLE_IN_STATION_REQUEST }
  }

  function failure(error) {
    return {
      type: batteriesConstants.GET_DELIVERABLE_IN_STATION_FAILURE,
      error,
    }
  }

  function success(results) {
    return {
      type: batteriesConstants.GET_DELIVERABLE_IN_STATION_SUCCESS,
      results,
    }
  }
}

function createBatteryInsertion(body) {
  return (dispatch) => {
    dispatch(request())
    batteriesService.createBatteryInsertion(body).then(
      (results) => {
        dispatch(
          alertActions.success(
            i18next.t('services.batteries.createBatteryInsertionSuccess'),
          ),
        )
      },
      (error) => {
        dispatch(
          alertActions.error(
            i18next.t('services.batteries.createBatteryInsertionError'),
          ),
        )
      },
    )
  }

  function request() {
    return { type: batteriesConstants.CREATE_BATTERY_INSERTION_REQUEST }
  }

  function success() {
    return { type: batteriesConstants.CREATE_BATTERY_INSERTION_SUCCESS }
  }

  function failure() {
    return { typr: batteriesConstants.CREATE_BATTERY_INSERTION_FAILURE }
  }
}

function assignToStation(body) {
  return (dispatch) => {
    dispatch(request())
    batteriesService.assignToStation(body).then(
      (results) => {
        dispatch(success())
        dispatch(alertActions.success('Batería cambiada de punto de entrega'))
        dispatch(getForLogged())
      },
      (error) => {
        dispatch(failure(error))
      },
    )
  }

  function request() {
    return { type: batteriesConstants.ASSIGN_TO_STATION_REQUEST }
  }

  function success() {
    return { type: batteriesConstants.ASSIGN_TO_STATION_SUCCESS }
  }

  function failure(error) {
    return { type: batteriesConstants.ASSIGN_TO_STATION_FAILURE }
  }
}
