export const setupUserState = (customerReducer) => {
  return {
    id: customerReducer.customer.id,
    mobilePhone: customerReducer.customer?.phone,
    idDocCode: customerReducer.customer?.idDocCode,
    idDocType: customerReducer.customer?.idDocType ?? 'NIF',
    firstName: customerReducer.customer?.firstName,
    secondSurname: customerReducer.customer?.secondSurname,
    lastName: customerReducer.customer?.lastName,
    birthday: customerReducer.customer?.birthday,
    country: customerReducer.countries?.find(
      (country) =>
        customerReducer.customer?.country === country.iso2,
    ) || { iso2: 'ES', displayName: 'España' },
    gender: customerReducer.customer?.gender,
    city: customerReducer.customer?.city,
    address: customerReducer.customer?.address,
    postalCode: customerReducer.customer?.postalCode,
    email: customerReducer.customer?.email,
  }
}
